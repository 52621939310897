import React from 'react';
import styled from 'styled-components';
import * as Mixins from '../Mixins';
import * as t from '../Typography';
import Colors from '../Colors';
import Img from 'gatsby-image';
import { Link, graphql } from 'gatsby';
import Layout, { Content } from '../components/Layout';

const BlogListWrapper = styled(Content)`
  padding-top: 130px;
  max-width: max-content;
`;

const ArticleItem = styled.div`
  ${Mixins.block({ axis: 'row' })}
  width: 700px;
  height: 190px;
  margin: 0 auto;
  border-radius: 7px;
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  padding: 10px 10px;
  transition: all 0.2s;
  margin-bottom: 50px;
  color: black;

  &:hover {
    box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  }
`;

const ArticleDetails = styled.div`
  ${Mixins.block({ axis: 'column', align: 'flex-start' })}
  width: 65%;
  margin-right: 20px;
  color: ${Colors.darkGrey_2};
`;

const Title = styled(t.H4)`
  font-size: 23px;
  color: rgba(0, 0, 0, 0.84);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
`;

const Date = styled.span`
  font-size: 13px;
`;

const Excerpt = styled(t.P)`
  display: block;
  height: 50px;
  width: 100%;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
`;

const ArticleImg = styled(Img)`
  width: 27%;
`;

const Image = styled.img`
  width: 27%;
`;

class IndexPage extends React.Component {
  renderImage(node) {
    const hasURI = node.frontmatter.imageURI;

    if (hasURI) {
      return <Image src={node.frontmatter.imageURI} />;
    }

    if (node.frontmatter.thumbnail.childImageSharp) {
      return <ArticleImg sizes={node.frontmatter.thumbnail.childImageSharp.sizes} />;
    }

    return <Image src={node.frontmatter.thumbnail.publicURL} />;
  }

  render() {
    const postList = this.props.data.allMarkdownRemark;

    return (
      <Layout>
        <BlogListWrapper>
          {postList.edges.map(({ node }) => (
            <Link to={`/blog${node.fields.slug}`} className="link">
              {node.frontmatter.path === '/blog' && (
                <ArticleItem className="post-list">
                  <ArticleDetails>
                    <Title noMargin>{node.frontmatter.title}</Title>
                    <Date>{node.frontmatter.date}</Date>
                    <Excerpt>{node.frontmatter.description ? node.frontmatter.description : node.excerpt}</Excerpt>
                  </ArticleDetails>
                  {this.renderImage(node)}
                </ArticleItem>
              )}
            </Link>
          ))}
        </BlogListWrapper>
      </Layout>
    );
  }
}
export default IndexPage;

export const listQuery = graphql`
  query ListQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM Do YYYY")
            title
            path
            thumbnail {
              publicURL
              childImageSharp {
                sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            imageURI
            description
          }
        }
      }
    }
  }
`;
